/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Loading } from '@/components';
import { updateMask as updateMaskDate } from '@/helpers/masks/generalDate';
import EventMainDateReport from '@/model/EventMainDateReport';
import validators from '@/helpers/validators';
import useForm from '@/hooks/useForm';
import api from '@/services/api';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ReportControllerProps } from '../../types';
import { FormInputFilter, SaleDateContent } from './ui';

interface SaleDateProps {
  reportController: ReportControllerProps;
  generatePDF: () => void;
}

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

export const SaleDate: React.FC<SaleDateProps> = ({ reportController, generatePDF }) => {
  const [state, setState] = useState<States>(States.default);
  const { saleDate, setSaleDate, eventId } = reportController;

  const { formData, formErrors, setErrors, onChangeFormInput, isFormValid, resetForm } = useForm({
    initialData: {
      startDate: '',
      endDate: '',
    },
    validators: {
      startDate: [validators.required, validators.maxLength(10)],
      endDate: [validators.required, validators.maxLength(10)],
    },
    formatters: { startDate: updateMaskDate, endDate: updateMaskDate },
  });

  const handleGetSaleDate = async (filter: boolean): Promise<void> => {
    try {
      setState(States.loading);
      if (!filter) {
        const { data } = await api.get<EventMainDateReport>(
          `event/report/${eventId}/sale-date?singleEvent=${reportController.singleEvent}`,
        );
        if (data) {
          setSaleDate(data);
        }
      } else if (filter) {
        setState(States.loading);
        const start = formData[FormInputFilter.startDate];
        const end = formData[FormInputFilter.endDate];
        const formattedStart = start.replace(/\//g, '-');
        const formattedEnd = end.replace(/\//g, '-');
        const { data } = await api.get<EventMainDateReport>(
          `event/report/${eventId}/${formattedStart}/${formattedEnd}/sale-date?singleEvent=false`,
        );
        if (data) {
          setSaleDate(data);
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleSubmitFilter = () => {
    const startDateRaw = formData[FormInputFilter.startDate];
    const endDateRaw = formData[FormInputFilter.endDate];

    const formatDate = (dateStr: string): string => {
      const [day, month, year] = dateStr.split('/');
      return `${year}-${month}-${day}`;
    };
    const startDate = formatDate(startDateRaw);
    const endDate = formatDate(endDateRaw);

    if (new Date(startDate) > new Date(endDate)) {
      setErrors({
        startDate: ['Erro!'],
      });
      toast.error('A data inicial não pode ser maior que a data final');
      return;
    }

    if (isFormValid()) {
      handleGetSaleDate(true);
    }
  };

  const handleClearFilter = () => {
    resetForm();
    handleGetSaleDate(false);
  };

  useEffect(() => {
    handleGetSaleDate(false);
  }, []);

  return (
    <>
      <Loading isVisible={state === 'loading'} />
      <SaleDateContent
        formData={formData}
        formErrors={formErrors}
        onChangeFormInput={onChangeFormInput}
        submitFilter={handleSubmitFilter}
        clearFilter={handleClearFilter}
        generatePDF={generatePDF}
        saleDate={saleDate}
      />
    </>
  );
};
