import { Button, InputText } from '@/components';
import { DataList } from '@/components/DataList';
import { CustomTable } from '@/components/Table';
import SuperCollapse from '@/components/sharedComponents/SuperCollapse';
import { toCurrency } from '@/helpers/masks/toCurrency';
import { UseFormReturn } from '@/hooks';
import EventMainDateReport from '@/model/EventMainDateReport';
import React from 'react';
import Search from '../../../../../assets/images/svg/search.svg';

export interface SaleDateContentProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  saleDate: EventMainDateReport | undefined;
  submitFilter: () => void;
  clearFilter: () => void;
  generatePDF: () => void;
}
// eslint-disable-next-line no-shadow
export enum FormInputFilter {
  startDate = 'startDate',
  endDate = 'endDate',
}

export const SaleDateContent: React.FC<SaleDateContentProps> = ({
  formData,
  formErrors,
  saleDate,
  onChangeFormInput,
  submitFilter,
  clearFilter,
  generatePDF,
}) => (
  <>
    <div className="d-flex justify-content-between">
      <h5>Vendas por data</h5>
      <div style={{ display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'center' }}>
        <div
          style={{ display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'center' }}
        >
          <InputText
            name="startDate"
            label="de"
            placeholder="00/00/0000"
            value={formData[FormInputFilter.startDate]}
            onChange={e => onChangeFormInput(FormInputFilter.startDate)(e.target.value)}
            error={formErrors.startDate && formErrors.startDate[0]}
          />
          <InputText
            name="endDate"
            label="até:"
            placeholder="00/00/0000"
            value={formData[FormInputFilter.endDate]}
            onChange={e => onChangeFormInput(FormInputFilter.endDate)(e.target.value)}
            error={formErrors.endDate && formErrors.endDate[0]}
          />
        </div>
        <div
          style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', gap: '15px' }}
        >
          <img
            src={Search}
            alt="Buscar"
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              backgroundColor: '#f0f0f0',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              width: '55px',
              height: '55px',
              marginBottom: '20px',
            }}
            onClick={submitFilter}
          />
          <div
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              backgroundColor: '#ae0a0a',
              boxShadow: '0 2px 4px rgba(186, 4, 4, 0.1)',
              width: '55px',
              height: '55px',
              marginBottom: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '18px',
              fontWeight: 'bold',
              color: '#ffffff',
            }}
            onClick={clearFilter}
          >
            x
          </div>
        </div>
      </div>
      <div>
        <Button theme="outlineDark" onClick={() => generatePDF()} title="Exportar relatório" />
      </div>
    </div>
    <div
      className="customTableScroll p-4"
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        gridColumnGap: '40px',
        overflow: 'auto',
        alignItems: 'end',
      }}
    >
      <DataList
        data={
          saleDate
            ? [
                {
                  title: 'Vendidos:',
                  content: saleDate.amountSold ? `${saleDate.amountSold}` : '-----',
                },
                {
                  title: 'Cortesias:',
                  content: saleDate.amountCourtesy ? saleDate.amountCourtesy : '-----',
                },
                {
                  title: 'Faturamento:',
                  content: saleDate.billingValue ? `${toCurrency(saleDate.billingValue)}` : '-----',
                },
                {
                  title: 'Descontos:',
                  content: saleDate.discounts ? `${toCurrency(saleDate.discounts)}` : '-----',
                },
                {
                  title: 'Faturamento com descontos:',
                  content: saleDate.billingValue ? `${toCurrency(saleDate.billingValue)}` : '-----',
                },
              ]
            : []
        }
      />
    </div>
    {saleDate &&
      saleDate.dates &&
      saleDate.dates.map((data, index) => (
        <React.Fragment key={`Date-${index}-${data.date}`}>
          <SuperCollapse
            width={'100%'}
            title={
              <CustomTable
                numberRowsPerPage={0}
                progressPending={false}
                theme="secondaryWithoutBorder"
                columns={[
                  {
                    name: 'Data',
                    selector: row => row.date,
                    width: '25%',
                  },
                  {
                    name: 'Tot Vendidos',
                    selector: row => row.amountSold,
                    width: '25%',
                  },
                  {
                    name: 'Cortesias',
                    selector: row => row.amountCourtesy,
                    width: '25%',
                  },
                  {
                    name: 'Valor total',
                    selector: row => row.value,
                    width: '25%',
                  },
                ]}
                data={
                  data.date
                    ? [
                        {
                          date: data.date,
                          amountSold: data.amountSold,
                          amountCourtesy: data.amountCourtesy,
                          value: toCurrency(data.value),
                        },
                      ]
                    : []
                }
              />
            }
            content={
              <>
                {data.events &&
                  data.events.length > 0 &&
                  data.events.map(event => (
                    <>
                      <h6>{event.eventName}</h6>
                      <CustomTable
                        key={event.eventId}
                        numberRowsPerPage={0}
                        progressPending={false}
                        theme="secondaryWithoutBorder"
                        columns={[
                          {
                            name: 'Ingresso',
                            selector: row => row.name,
                            width: '55%',
                          },
                          {
                            name: 'Vendidos',
                            selector: row => row.amountSold,
                            width: '15%',
                          },
                          {
                            name: 'Cancelados',
                            selector: row => row.amountCancel,
                            width: '15%',
                          },
                          {
                            name: 'Valor',
                            selector: row => row.value,
                            width: '15%',
                          },
                        ]}
                        data={
                          event.details && event.details.length > 0
                            ? event.details.map(detail => ({
                                name: `${detail.ticket.name} - ${detail.batch.name}`,
                                amountSold: detail.amountSold || '---',
                                amountCancel: detail.amountCancel || '---',
                                ticketsCancel: detail.value || '---',
                                value: toCurrency(detail.value) || '---',
                              }))
                            : []
                        }
                      />
                    </>
                  ))}
              </>
            }
          />
        </React.Fragment>
      ))}
  </>
);
