import Address from './Address';
import Artist from './Artist';
import Contractor from './Contractor';
import Event from './Event';
import EventCategory from './EventCategory';
import EventType from './EventType';
import ProductLayout from './ProductLayout';
import TextSize from './TextSize';

export default interface EventGeneralInformation {
  id?: string;
  fatherEvent?: string;
  name: string;
  posName: string;
  passportName?: string;
  establishmentName: string;
  eventType: EventType;
  address: Address;
  startDate: Date;
  endDate: Date;
  websiteStartSaleDate: Date;
  websiteEndSaleDate: Date;
  eventCategory: EventCategory;
  contractors: Contractor[];
  artists?: Artist[];
  censure: number;
  facebookUrl: string;
  instagramUrl: string;
  imageBase64: string;
  imageDetailBase64: string;
  imagePosBase64: string;
  imageBluetooth: string;
  productLayout: ProductLayout;
  showImageLogo: boolean;
  publishWebsite: boolean;
  sendTicketEmail: boolean;
  textSize: TextSize;
  websiteDescription: string;
  imageDescriptionUrl: string;
  discountDescription: string;
  pixel?: string;
}

export const parseGeneralInformation = (event: Event): EventGeneralInformation => {
  let fatherEvent: string | undefined;
  if (event.fatherEvent && event.fatherEvent.id) {
    fatherEvent = event.fatherEvent.id;
  }
  const egi: EventGeneralInformation = {
    id: event.id,
    fatherEvent,
    name: event.name,
    posName: event.posName,
    passportName: event.passportName,
    establishmentName: event.establishmentName,
    eventType: event.eventType,
    address: event.address,
    startDate: event.startDate,
    endDate: event.endDate,
    websiteStartSaleDate: event.startDate,
    websiteEndSaleDate: event.endDate,
    eventCategory: event.eventCategory,
    contractors: event.contractors,
    censure: event.censure,
    facebookUrl: event.facebookUrl,
    instagramUrl: event.instagramUrl,
    imageBase64: event.imageBase64,
    imageDetailBase64: event.imageBase64,
    imagePosBase64: event.imagePosBase64,
    imageBluetooth: event.imageBluetooth,
    productLayout: event.productLayout,
    showImageLogo: false,
    publishWebsite: event.publishWebsite,
    sendTicketEmail: event.sendTicketEmail,
    textSize: event.textSize,
    websiteDescription: event.websiteDescription,
    imageDescriptionUrl: event.imageDescriptionUrl,
    discountDescription: event.websiteDescription,
    pixel: event.pixel,
  };
  return egi;
};
